<template>
  <div class="mt-12 tablet:mt-18 xl:mt-12">
    <div class="hidden xl:flex xl:flex-col xl:gap-4">
      <div class="w-full overflow-hidden">
        <div class="scroll-content scroll-left w-[4220px] h-[122px]">
          <img 
            src="@/assets/publishing/row-01.webp" 
            alt="publishing game images row 1"
          >
          <img 
            src="@/assets/publishing/row-01.webp" 
            class="ml-5"
            alt="publishing game images row 1 Duplicate"
          >
        </div>
      </div>
      <div>
        <div class="scroll-content scroll-right w-[4220px] h-[122px] -ml-[61px]">
          <img 
            src="@/assets/publishing/row-02.webp" 
            alt="publishing game images row 2"
          >
          <img 
            src="@/assets/publishing/row-02.webp" 
            class="ml-5"
            alt="publishing game images row 2 Duplicate"
          >
        </div>
      </div>
      <div>
        <div class="scroll-content scroll-left w-[4220px] h-[122px]">
          <img 
            src="@/assets/publishing/row-03.webp" 
            alt="publishing game images row 3"
          >
          <img 
            src="@/assets/publishing/row-03.webp" 
            class="ml-5"
            alt="publishing game images row 3 Duplicate"
          >
        </div>
      </div>
    </div>
    <div
      v-if="$screen.breakpoint === 'pc'"
      v-observe-visibility="visibilityChanged"
      style="height: 1px;"
    />
    <div 
      class="mt-0 mx-4 mb-24 tablet:mx-8 tablet:mb-[120px] xl:mt-20 xl:mb-40 xl:max-w-7xl xl:mx-auto xl:h-screen"
      data-aos="fade-up"
      data-aos-duration="900"
    >
      <div 
        ref="leftSide" 
        class="w-full xl:w-[628px] xl:float-left"
      >
        <div class="font-ClashDisplayMedium text-[17px] tablet:text-[24px] tablet:font-medium tablet:leading-[1.33] tablet:-tracking-[0.2px] xl:text-[36px] xl:leading-[1.22] xl:tracking-normal text-orange-500">
          Publishing
        </div>
        <div class="mt-1 text-[40px] leading-[1.13] -tracking-[0.67px] font-black text-black tablet:mt-2.5 tablet:text-[64px] tablet:leading-[1.06] tablet:-tracking-[1.07px] xl:mt-2 xl:text-[96px] xl:leading-[0.99] xl:-tracking-[1.48px]">
          Submit 
          <br>
          Your Game
        </div>
      </div>
      <div 
        ref="rightSide" 
        class="mt-5 w-full flex flex-col flex-1 tablet:mt-6 xl:float-right xl:w-[520px] xl:mt-3"
      >
        <div 
          class="relative" 
          @click.stop="toggleDropdown"
        >
          <div 
            class="border border-gray-200 bg-white flex justify-between items-center cursor-pointer hover:bg-gray-50
              w-[90px] h-9 py-2 pl-3 pr-4 rounded-[18px] ml-0
              tablet:w-[100px] tablet:h-11 tablet:py-2.5 tablet:pr-[18px] tablet:pl-3 tablet:rounded-[24px]
              xl:ml-auto"
          >
            <img 
              src="@/assets/publishing/icon-earth.svg" 
              class="w-4 h-4 tablet:w-5 tablet:h-5" 
              alt="지구본 모양 아이콘"
            >
            <span class="text-base leading-[1.25] tablet:w-[23px] tablet:h-6 tablet:text-lg tablet:leading-[1.33] text-gray-600">{{ selectedLanguage.toUpperCase() }}</span>
            <i class="w-3 h-3 mb-1 tablet:w-3.5 tablet:h-3.5 tablet:mb-0 icon-arrow-down" />
          </div>
          <div 
            v-if="isOpen" 
            class="absolute w-[145px] left-0 mt-2 bg-white border border-gray-200 z-10 dropdown-menu xl:right-0 xl:left-auto"
          >
            <ul class="py-1">
              <li 
                v-for="(language, index) in languages" 
                :key="index" 
                class="px-4 py-2.5 h-10 text-gray-600 hover:bg-gray-100 cursor-pointer"
                @click="selectLanguage(language)"
              >
                <div 
                  v-if="selectedLanguage === language.code" 
                  class="flex items-center justify-between"
                >
                  <span class="text-base font-bold leading-5 text-orange-500">{{ language.name }}</span>
                  <i class="w-5 h-5 text-orange-500 icon-check" />
                </div>
                <div v-else>
                  {{ language.name }}
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="w-full h-[1px] mt-6 bg-gray-200 tablet:mt-8 xl:hidden" />
        <div class="w-full mt-8 tablet:mt-10 xl:mt-2">
          <div v-if="selectedLanguage === 'en'">
            <LangEng />
          </div>
          <div v-if="selectedLanguage === 'kr'">
            <LangKor />
          </div>
          <div v-if="selectedLanguage === 'cn'">
            <LangChi />
          </div>
          <div v-if="selectedLanguage === 'jp'">
            <LangJap />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LangEng from './components/langEng.vue';
import LangKor from './components/langKor.vue';
import LangChi from './components/langChi.vue';
import LangJap from './components/langJap.vue';

export default {
  name: 'Publishing',
  components: {
    LangEng,
    LangKor,
    LangChi,
    LangJap
  },
  data() {
    return {
      isOpen: false,
      selectedLanguage: 'en',
      languages: [
        { code: 'en', name: 'English' },
        { code: 'kr', name: 'Korean' },
        { code: 'cn', name: 'Chinese' },
        { code: 'jp', name: 'Japanese' },
      ],
      startTopY: 0,
      offset: 0
    };
  },
  created(){
    // const browserLanguage = navigator.language || navigator.userLanguage || navigator.systemLanguage;
    // console.log('browserLanguage', browserLanguage);
    if (this.$route.query.lang) this.selectedLanguage = this.$route.query.lang;
    else if (navigator.languages.length > 0) {
      let tempLang = navigator.languages[0].split('-')[0];
      if (tempLang === 'ko') tempLang = 'kr';
      if (tempLang === 'zh') tempLang = 'cn';
      if (tempLang === 'ja') tempLang = 'jp';
      this.selectedLanguage = tempLang;
    }
    else this.selectedLanguage = 'en';
    window.fbq('track', 'cookapps_publishing_view');
  },
  mounted() {
    // Close dropdown when clicking outside
    document.addEventListener('click', this.closeDropdown);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeDropdown);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    closeDropdown(event) {
      if (this.isOpen && !this.$el.contains(event.target)) {
        this.isOpen = false;
      }
    },
    selectLanguage(language) {
      this.selectedLanguage = language.code;
      this.$router.replace({ query: {...this.$route.query, lang: this.selectedLanguage }});
      this.toggleDropdown();
    },
    visibilityChanged(isVisible) {
      if(!isVisible && this.$refs.leftSide.clientHeight && this.$refs.rightSide.clientHeight) {
        this.startTopY = window.scrollY > 552 ? 552 : window.scrollY;
        window.addEventListener('scroll', this.handleScroll);
      }
    },
    handleScroll() {
      const topWindow = window.scrollY;
      this.offset = topWindow - this.startTopY;
      if(this.offset < 15){
        this.offset = 0;
      } 
      this.$refs.leftSide.style.transform = 'translateY('+this.offset+'px)';
    },
  }
};
</script>

<style lang="scss">
.scroll-content {
  display: inline-flex;
  white-space: nowrap;
}

.custom_input_class.el-form-item {
  margin-bottom: 0px;
}

:is(.pc) .custom_input_class {
  .el-checkbox__label {
      font-size: 16px !important;
      font-weight: normal !important;
      font-stretch: normal !important;
      font-style: normal !important;
      line-height: 1.25 !important;
      letter-spacing: -0.1px !important;
  }
  .el-form-item__content { 
    width: 100%;
  }
  .el-textarea__inner {
    height: 48px;
    font-size: 16px;
    border-color: #e1e1e1;
    color: #333333;
    padding: 14px 16px;
  }
  .el-input__inner {
    height: 48px;
    font-size: 16px;
    border-color: #e1e1e1;
    color: #333333;
    padding: 0 16px;
  }
  .el-input__prefix {
    padding-left: 9px;
  }
  .el-input--prefix .el-input__inner {
      padding-left: 48px;
  }
  .el-input__icon.icon-folder-add {
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
      
      font-size: 24px;
      color: #999999;
    }
  }
}
:is(.tablet) .custom_input_class {
  .el-checkbox__label {
      font-size: 16px !important;
      font-weight: normal !important;
      font-stretch: normal !important;
      font-style: normal !important;
      line-height: 1.25 !important;
      letter-spacing: -0.1px !important;
  }
  .el-form-item__content { 
    width: 100%;
  }
  .el-textarea__inner {
    height: 48px;
    font-size: 16px;
    border-color: #e1e1e1;
    color: #333333;
    padding: 14px 16px;
  }
  .el-input__inner {
    width: 300px;
    height: 48px;
    font-size: 16px;
    border-color: #e1e1e1;
    color: #333333;
  }
  .el-input__prefix {
    padding-left: 9px;
  }
  .el-input--prefix .el-input__inner {
      padding-left: 48px;
  }
  .el-input__icon.icon-folder-add {
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
      
      font-size: 24px;
      color: #999999;
    }
  }
}
:is(.mobile) .custom_input_class {
  .el-checkbox__label {
    font-size: 14px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.29 !important;
    letter-spacing: -0.1px !important;
  }
  .el-form-item__content { 
    width: 100%;
  }
  .el-textarea__inner {
    height: 42px;
    font-size: 14px;
    line-height: 1.29;
    letter-spacing: -0.1px;
    border-color: #e1e1e1;
    color: #333333;
    padding: 12px;
  }
  // .el-input__inner {
  //   height: 42px;
  //   font-size: 14px;
  //   border-color: #e1e1e1;
  //   color: #333333;
  //   padding: 0 12px;
  // }
  .el-input__inner {
    height: 48px;
    font-size: 16px;
    border-color: #e1e1e1;
    color: #333333;
    padding: 0 14px;

    width: 114.28571428571428571428571428571%;

    transform: scale(0.875);
    transform-origin: left top;
  }
  .el-input__prefix {
    padding-left: 9px;
  }
  .el-input--prefix .el-input__inner {
      padding-left: 48px;
  }
  .el-input__icon.icon-folder-add {
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
      
      font-size: 24px;
      color: #999999;
    }
  }
}

.scroll-right {
  animation: scrollRight 100s linear infinite;
}
.scroll-left {
  animation: scrollLeft 100s linear infinite;
}

@keyframes scrollRight {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(0); }
}
@keyframes scrollLeft {
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
}

.dropdown-menu {
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
  transform: translateX(30%);
  @media screen and (max-width: 768px) {
    transform: translateX(0);
  }

  &:after,
  &:before {
    bottom: 100%;
    left: 25%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  
  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
    border-width: 5px;
    margin-left: -5px;
  }
  &:before {
    border-color: rgba(225, 225, 225, 0);
    border-bottom-color: #e1e1e1;
    border-width: 6px;
    margin-left: -6px;
  }
}
</style>
