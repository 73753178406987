<template>
  <div class="font-NotoSansSc">
    <div>
      <form 
        ref="publishingForm"
        class="w-full xl:mt-2 mobile:mt-0"
        :model="publishingForm"
        @submit.prevent="submitForm"
      >
        <div>
          <div
            class="
              text-gray-700 font-bold mt-0
              tablet:text-[18px] tablet:leading-[1.33]
              text-base leading-[1.25]
            "
          >
            姓名 <span class="text-orange-500">*</span>
          </div>
          <div class="box-border relative w-full h-[42px] mt-2.5 tablet:h-12 tablet:mt-3">
            <input
              v-model="publishingForm.fullName"
              type="text"
              class="w-full h-full border border-gray-200 rounded-[4px] bg-white text-base leading-5 text-gray-800 
                p-3 tablet:px-4 tablet:py-[14px]
                placeholder:text-sm placeholder:leading-[1.29] tablet:placeholder:text-base tablet:placeholder:leading-5 
                placeholder:text-gray-400 focus-visible:border focus-visible:border-gray-800 focus:outline-none active:border-gray-800"
              placeholder="请输入姓名"
              :class="inputClasses(publishingFormErrors.fullName)"
            >
          </div>
          <p 
            v-if="publishingFormErrors.fullName" 
            class="w-full h-[18px] text-[13px] leading-[1.38] -tracking-[0.1px] text-red-400 mt-1"
          >
            {{ publishingFormErrors.fullName }}
          </p>
        </div>
        <div class="mt-8">
          <div
            class="
              text-gray-700 font-bold mt-0
              tablet:text-[18px] tablet:leading-[1.33]
              text-base leading-[1.25]
            "
          >
            邮箱 <span class="text-orange-500">*</span>
          </div>
          <div class="box-border relative w-full h-[42px] mt-2.5 tablet:h-12 tablet:mt-3">
            <input
              v-model="publishingForm.email"
              type="text"
              class="w-full h-full border border-gray-200 rounded-[4px] bg-white text-base leading-5 text-gray-800 
                p-3 tablet:px-4 tablet:py-[14px]
                placeholder:text-sm placeholder:leading-[1.29] tablet:placeholder:text-base tablet:placeholder:leading-5 
                placeholder:text-gray-400 focus-visible:border focus-visible:border-gray-800 focus:outline-none active:border-gray-800"
              placeholder="example@cookapps.com"
              :class="inputClasses(publishingFormErrors.email)"
            >
          </div>
          <p 
            v-if="publishingFormErrors.email" 
            class="w-full h-[18px] text-[13px] leading-[1.38] -tracking-[0.1px] text-red-400 mt-1"
          >
            {{ publishingFormErrors.email }}
          </p>
        </div>
        <div class="mt-8">
          <div
            class="
              text-gray-700 font-bold mt-0
              tablet:text-[18px] tablet:leading-[1.33]
              text-base leading-[1.25]
            "
          >
            游戏名称 <span class="text-orange-500">*</span>
          </div>
          <div class="box-border relative w-full h-[42px] mt-2.5 tablet:h-12 tablet:mt-3">
            <input
              v-model="publishingForm.gameTitle"
              type="text"
              class="w-full h-full border border-gray-200 rounded-[4px] bg-white text-base leading-5 text-gray-800 
                p-3 tablet:px-4 tablet:py-[14px]
                placeholder:text-sm placeholder:leading-[1.29] tablet:placeholder:text-base tablet:placeholder:leading-5 
                placeholder:text-gray-400 focus-visible:border focus-visible:border-gray-800 focus:outline-none active:border-gray-800"
              placeholder="请输入游戏名称"
              :class="inputClasses(publishingFormErrors.gameTitle)"
            >
          </div>
          <p 
            v-if="publishingFormErrors.gameTitle" 
            class="w-full h-[18px] text-[13px] leading-[1.38] -tracking-[0.1px] text-red-400 mt-1"
          >
            {{ publishingFormErrors.gameTitle }}
          </p>
        </div>
        <div class="mt-8">
          <div
            class="
              text-gray-700 font-bold mt-0
              tablet:text-[18px] tablet:leading-[1.33]
              text-base leading-[1.25]
            "
          >
            游戏介绍 <span class="text-orange-500">*</span>
          </div>
          <div class="box-border relative w-full mt-3">
            <textarea
              v-model="publishingForm.gameDescription"
              type="text"
              class="w-full h-[202px] p-3 border border-gray-200 rounded-[4px] bg-white text-gray-800 resize-none
                tablet:h-[154px] tablet:px-4 tablet:py-3 text-base leading-5
                placeholder:text-sm placeholder:leading-[1.71] tablet:placeholder:text-base tablet:placeholder:leading-[1.63] 
                placeholder:text-gray-400 focus-visible:border focus-visible:border-gray-800 focus:outline-none active:border-gray-800"
              placeholder="自由形式"
              :class="inputClasses(publishingFormErrors.gameDescription)"
            />
          </div>
          <p 
            v-if="publishingFormErrors.gameDescription" 
            class="w-full h-[18px] text-[13px] leading-[1.38] -tracking-[0.1px] text-red-400"
          >
            {{ publishingFormErrors.gameDescription }}
          </p>
          <el-upload
            class="w-full mt-3"
            action=""
            multiple
            :auto-upload="false"
            :file-list="uploadList"
            :on-change="handleUploadChange"
            :show-file-list="false"
          >
            <div 
              class="border border-gray-200 bg-white rounded-[4px] text-gray-600 text-center cursor-pointer flex gap-2 items-center
                w-[calc(100vw-32px)] h-[42px] py-[11px] px-3 text-sm leading-[1.29]
                tablet:w-[calc(100vw-64px)] xl:w-[520px] xl:h-12 xl:py-3 xl:pl-3 xl:pr-4 xl:text-lg xl:leading-6 "
            >
              <div class="w-5 h-5 tablet:w-6 tablet:h-6">
                <img 
                  src="@/assets/publishing/icon-folder-add.svg" 
                  class="w-5 h-5 tablet:w-6 tablet:h-6" 
                  alt="file upload icon"
                >
              </div>
              <div class="text-sm leading-[1.29] tablet:text-base tablet:leading-5 text-gray-400">
                如有请附上(选填项)
              </div>
            </div>
          </el-upload>
          <div v-if="uploadList.length > 0">
            <div 
              v-for="(uploadFile, index) in uploadList" 
              :key="index" 
              class="w-full h-[30px] py-1.5 pl-1.5 pr-4 tablet:h-8 tablet:py-[7px] tablet:pl-2 tablet:pr-3 flex items-center justify-between hover:bg-gray-50" 
              :class="{'mt-1.5': index === 0}"
              @mouseover="hoverUploadIndex = index"
              @mouseleave="hoverUploadIndex = null"
            >
              <div class="flex items-center">
                <img 
                  src="@/assets/publishing/icon-document.svg" 
                  class="icon-link w-4 h-4 mr-1.5"
                  alt="link icon"
                >
                <span class="text-sm leading-[18px] text-gray-600">{{ uploadFile.name }}</span>
              </div>
              <div
                v-if="hoverUploadIndex === index"
                class="w-3 h-3 cursor-pointer" 
                @click.prevent="removeFileList(index)"
              >
                <img 
                  src="@/assets/publishing/icon-close.svg" 
                  class="w-3 h-3 icon-link"
                  alt="close icon"
                >
              </div>
            </div>
          </div>
        </div>
        <div class="mt-8">
          <div
            class="
              text-gray-700 font-bold mt-0
              tablet:text-[18px] tablet:leading-[1.33]
              text-base leading-[1.25]
            "
          >
            游戏试玩视频 <span class="text-orange-500">*</span>
          </div>
          <div class="mt-2.5 gap-2 flex items-center tablet:mt-3 tablet:gap-2.5">
            <div class="box-border relative flex-grow h-[42px] tablet:h-12">
              <input
                v-model="gamePlayVideoLink"
                type="text"
                class="w-full h-full border border-gray-200 rounded-[4px] bg-white text-base leading-5 text-gray-800 
                  p-3 tablet:px-4 tablet:py-[14px]
                  placeholder:text-sm placeholder:leading-[1.29] tablet:placeholder:text-base tablet:placeholder:leading-5 
                  placeholder:text-gray-400 focus-visible:border focus-visible:border-gray-800 focus:outline-none active:border-gray-800"
                placeholder="请上传视频链接"
                :class="inputClasses(publishingFormErrors.gamePlayVideoLinks)"
              >
            </div>
            <button 
              type="button"
              class="box-border flex items-center justify-center min-w-[72px] h-[42px] text-base leading-[1.25] rounded-[4px] border border-gray-200 bg-white text-gray-600 text-center cursor-pointer hover:bg-gray-50
                tablet:min-w-[84px] tablet:h-12 tablet:text-lg tablet:leading-6"
              @click.prevent="addGamePlayVideoLink"
            >
              添加
            </button>
          </div>
          <p 
            v-if="publishingFormErrors.gamePlayVideoLinks" 
            class="w-full h-[18px] text-[13px] leading-[1.38] -tracking-[0.1px] text-red-400 mt-1"
          >
            {{ publishingFormErrors.gamePlayVideoLinks }}
          </p>
          <div v-if="publishingForm.gamePlayVideoLinks.length > 0">
            <div 
              v-for="(videoLink, index) in publishingForm.gamePlayVideoLinks" 
              :key="index" 
              class="w-full h-[30px] py-1.5 pl-1.5 pr-4 tablet:h-8 tablet:py-[7px] tablet:pl-2 tablet:pr-3 flex items-center justify-between hover:bg-gray-50" 
              :class="{'mt-1.5': index === 0}"
              @mouseover="hoverVideoLinkIndex = index"
              @mouseleave="hoverVideoLinkIndex = null"
            >
              <div class="flex items-center">
                <img 
                  src="@/assets/publishing/icon-link.svg" 
                  class="icon-link w-4 h-4 mr-1.5"
                  alt="link icon"
                >
                <span class="text-sm leading-[18px] text-gray-600">{{ videoLink.url }}</span>
              </div>
              <div
                v-if="hoverVideoLinkIndex === index"
                class="w-3 h-3 cursor-pointer" 
                @click.prevent="removeVideoLink(index)"
              >
                <img 
                  src="@/assets/publishing/icon-close.svg" 
                  class="w-3 h-3 icon-link"
                  alt="close icon"
                >
              </div>
              <div
                v-else-if="$screen.breakpoint == 'mobile'"
                class="w-3 h-3 cursor-pointer" 
                @click.prevent="removeVideoLink(index)"
              >
                <img 
                  src="@/assets/publishing/icon-close.svg" 
                  class="w-3 h-3 icon-link"
                  alt="close icon"
                >
              </div>
            </div>
          </div>
        </div>
        <div class="mt-8">
          <div
            class="
              text-gray-700 font-bold mt-0
              tablet:text-[18px] tablet:leading-[1.33]
              text-base leading-[1.25]
            "
          >
            研发团队名称
          </div>
          <div class="box-border relative w-full h-[42px] mt-2.5 tablet:h-12 tablet:mt-3">
            <input
              v-model="publishingForm.studioName"
              type="text"
              class="w-full h-full border border-gray-200 rounded-[4px] bg-white text-base leading-5 text-gray-800 
                p-3 tablet:px-4 tablet:py-[14px]
                placeholder:text-sm placeholder:leading-[1.29] tablet:placeholder:text-base tablet:placeholder:leading-5 
                placeholder:text-gray-400 focus-visible:border focus-visible:border-gray-800 focus:outline-none active:border-gray-800"
              placeholder="请输入研发团队名称(选填项)"
            >
          </div>
        </div>
        <div class="mt-8">
          <div
            class="
              text-gray-700 font-bold mt-0
              tablet:text-[18px] tablet:leading-[1.33]
              text-base leading-[1.25]
            "
          >
            研发所在国家
          </div>
          <div class="box-border relative w-full h-[42px] mt-2.5 tablet:h-12 tablet:mt-3">
            <input
              v-model="publishingForm.studioLocation"
              type="text"
              class="w-full h-full border border-gray-200 rounded-[4px] bg-white text-base leading-5 text-gray-800 
                p-3 tablet:px-4 tablet:py-[14px]
                placeholder:text-sm placeholder:leading-[1.29] tablet:placeholder:text-base tablet:placeholder:leading-5 
                placeholder:text-gray-400 focus-visible:border focus-visible:border-gray-800 focus:outline-none active:border-gray-800"
              placeholder="输入研发所在国家(选填项)"
            >
          </div>
        </div>
        <div class="flex justify-between mt-6 h-[46px] items-start tablet:mt-8 tablet:h-auto">
          <el-checkbox 
            v-model="publishingForm.isConsentAgree" 
            class="w-4 h-4 tablet:w-5 tablet:h-5" 
            style="font-family: 'Noto Sans SC' !important;"
          >
            同意收集与使用个人信息 <span class="text-orange-500">*</span>
          </el-checkbox>
          <div>
            <router-link
              to="/tos-n-pp?start=1&lang=tw"
              class="text-[14px] leading-[1.64] -tracking-[0.1px] tablet:text-base tablet:leading-5 text-orange-500 hover:underline"
            >
              详情
            </router-link>
          </div>
        </div>
        <!-- Submit Button -->
        <div class="mt-10 tablet:mt-12 xl:mt-8">
          <button
            type="submit"
            class="w-full h-12 py-3 px-[141.5px] rounded-[24px] text-lg leading-[1.33] bg-orange-500 font-bold text-white hover:bg-orange-600
              tablet:h-16 tablet:px-[220.5px] tablet:py-[17px] tablet:rounded-5xl tablet:text-[24px] tablet:leading-[30px] tablet:-tracking-[0.2px]"
          >
            提交
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { getUploadUrl } from '@/api/job';
import { registerPublishing } from '../../../api/publishing';

export default {
  name: 'LangEng',
  data() {
    return {
      publishingForm: {
        fullName: '',
        email: '',
        gameTitle: '',
        gameDescription: '',
        fileList: [],
        descriptionFiles: [],
        gamePlayVideoLinks: [],
        studioName: '',
        studioLocation: '',
      },
      publishingFormErrors: {
        fullName: '',
        email: '',
        gameTitle: '',
        gameDescription: '',
        gamePlayVideoLinks: '',
      },
      hoverVideoLinkIndex: null,
      gamePlayVideoLink: '',
      uploadList: [],
      hoverUploadIndex: null,
    };
  },
  methods: {
    inputClasses(error) {
      return error ? 'border-red-400' : '';
      // return `w-full px-4 py-2 border ${error ? 'border-red-400' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`;
    },
    async removeFileList(index) {
      this.uploadList.splice(index, 1);
    },
    handleUploadChange(file, fileList) {
      this.uploadList = fileList;
    },
    validateField(field) {
      if (field === 'fullName') {
        if (!this.publishingForm.fullName) {
          this.publishingFormErrors.fullName = '这是必填项目。';
        } else {
          this.publishingFormErrors.fullName = '';
        }
      }

      if (field === 'email') {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!this.publishingForm.email) {
          this.publishingFormErrors.email = '这是必填项目。';
        } else if (!emailRegex.test(this.publishingForm.email)) {
          this.publishingFormErrors.email = '无效的电子邮件地址。';
        } else {
          this.publishingFormErrors.email = '';
        }
      }

      if (field === 'gameTitle') {
        if (!this.publishingForm.gameTitle) {
          this.publishingFormErrors.gameTitle = '这是必填项目。';
        } else {
          this.publishingFormErrors.gameTitle = '';
        }
      }

      if (field === 'gameDescription') {
        if (!this.publishingForm.gameDescription) {
          this.publishingFormErrors.gameDescription = '这是必填项目。';
        } else {
          this.publishingFormErrors.gameDescription = '';
        }
      }

      if (field === 'gamePlayVideoLinks') {
        if (this.publishingForm.gamePlayVideoLinks.length < 1) {
          this.publishingFormErrors.gamePlayVideoLinks = '这是必填项目。';
        } else {
          this.publishingFormErrors.gamePlayVideoLinks = '';
        }
      }
    },
    addGamePlayVideoLink() {
      const urlRegex = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-./?%&=_]*)?$/;
      if (this.gamePlayVideoLink) {
        if (!urlRegex.test(this.gamePlayVideoLink)) {
          this.publishingFormErrors.gamePlayVideoLinks = '无效的URL。';
        } else {
          this.publishingFormErrors.gamePlayVideoLinks = '';
          this.publishingForm.gamePlayVideoLinks.push({ url: this.gamePlayVideoLink });
          this.gamePlayVideoLink = '';
        }
      }
    },
    removeVideoLink(index) {
      this.publishingForm.gamePlayVideoLinks.splice(index, 1);
    },
    async submitForm() {
      this.validateField('fullName');
      this.validateField('email');
      this.validateField('gameTitle');
      this.validateField('gameDescription');
      this.validateField('gamePlayVideoLinks');

      if (!this.publishingFormErrors.fullName && !this.publishingFormErrors.email && !this.publishingFormErrors.gameTitle && !this.publishingFormErrors.gameDescription && !this.publishingFormErrors.gamePlayVideoLinks) {
        const fileList = await this.uploadFiles(this.uploadList);
        const urlList = this.publishingForm.gamePlayVideoLinks;

        const resigterResult = await registerPublishing({
          publishingForm: this.publishingForm,
          fileList,
          urlList
        });


        if (resigterResult.data.success) {
          this.$router.push('/publishing/complete?lang=cn');
        }
      }
    },
    async uploadFiles(data) {
      let fileList = [];

      if (data.length) {
        const sParam = data.map(file => {
          return {
            name: file.name,
            type: file.raw.type
          };
        });

        const signedResult = await getUploadUrl({ items: sParam });
        const uploadUrlList = signedResult.data.uploadUrlList;
        const downloadUrlList = signedResult.data.downloadUrlList;

        const promiseList = uploadUrlList.map((url, idx) => {
          return new Promise((resolve) => {
            axios.put(url, data[idx].raw, {
              headers: {
                'Content-Type': data[idx].raw.type
              }
            }).then(() => {
              resolve(true);
            }).catch(() => {
              resolve(false);
            });
          });
        });

        const result = await Promise.all(promiseList);

        if (result.indexOf(false) > -1) {
          this.$alert(this.$t('recruit.apply_upload_fail'), this.$t('recruit.apply_fail_title'), { dangerouslyUseHTMLString: true });
          this.dialogLoading = false;

          return false;
        }

        downloadUrlList.forEach((url, idx) => {
          sParam[idx].url = url;
        });

        fileList = downloadUrlList.map((url, idx) => {
          return {
            ...sParam[idx],
            url
          };
        });
      }

      return fileList;
    },
  }
};
</script>

<style lang="scss" scoped>
.video-link {
  &:hover {
    background-color: #f5f5f5;
  }
}
</style>
